<template>
  <DialogFormWrapper>
    <template #form>
      <v-row class="mt-4">
        <v-col cols="12">
          <v-text-field
            outlined
            label="Email"
            :value="contact.email"
            readonly
            placeholder="Kontakt nie ma przypisanego adresu email"
          />
        </v-col>
      </v-row>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :disabled="!contact.email"
        :loading="isProcessing"
        @click="handleClientAppAccess"
      >
        {{ submitText }}
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  computed: {
    ...mapState({
      contact: state => state.layout.dialog.item,
      submitText: state => state.layout.dialog.data.text,
      isProcessing: state => state.client.processing
    })
  },
  methods: {
    ...mapActions({
      handleClientAppAccess: function(dispatch) {
        const { email, id, passwordExists } = this.contact
        return dispatch('client/handleClientAppAccess', { email, id, passwordExists })
      }
    }),
  }
}
</script>
